import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { reservationSelector } from '@/store/selector';
import CustomerInfo from '@/components/CustomerInfo';
import { loadReservation } from '@/features/reservation/reservationSlice';
import { useDispatch } from 'react-redux';
import { Container, Grid, GridRow } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import ReservationDetailInfo from '@/components/ReservationDetailInfo';
import { API_STATUS } from '@/api/api';

const useStyles = createUseStyles(
  {
    root: () => ({
      padding: '2rem',
    }),
  },
  { name: 'ReservationDetailView' },
);

const ReservationDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reservationStore = reservationSelector();
  const params = useParams();
  const { id } = params;
  const reservationId = id;

  useEffect(() => {
    if (!reservationStore.reservationDetailList[reservationId]) {
      let request = {
        reservationId: reservationId,
      };
      dispatch(loadReservation(request));
    }
  }, []);

  const reservation = reservationStore.reservationDetailList[reservationId];

  const visible =
    reservationStore.reservationDetailList[reservationId]?.loadReservationStatus == API_STATUS.IDLE;

  return (
    visible && (
      <Container fluid className={classes.root}>
        <Grid columns={1} stackable divided="vertically">
          <GridRow>
            <Grid.Column>
              <CustomerInfo reservation={reservation} />
            </Grid.Column>
          </GridRow>
          <GridRow>
            <Grid.Column>
              <ReservationDetailInfo reservation={reservation} />
            </Grid.Column>
          </GridRow>
        </Grid>
      </Container>
    )
  );
};

export default React.memo(ReservationDetailView);
