import React from 'react';
import { createUseStyles } from 'react-jss';
import { Modal, Image } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';

const useStyles = createUseStyles(
  {
    root: (height) => ({
      height,
      '&.visible.transition': {
        display: 'flex !important',
        flexDirection: 'column',
      },
      '&.ui.modal > .content': {
        display: 'flex !important',
        flex: 1,
        flexDirection: 'column',
        padding: '1rem',
        '& > .ui.form': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    }),
    header: {
      display: 'flex',
      alignItems: 'baseline',
      fontWeight: 'normal',
    },
    close: {
      cursor: 'pointer',
    },
    divider: {
      color: Colors.grayTwo,
      marginLeft: '1.5rem',
      marginRight: '1rem',
    },
    fields: {
      margin: '2rem auto',
      padding: '0 1rem',
      width: 510,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1rem 1rem 0 1rem',
      margin: '0 -1rem',
      borderTop: `1px solid ${Colors.grayTwo}`,
      textAlign: 'end',
      '& > button': {
        minWidth: 148,
        height: 36,
        margin: 0,
        '&:not(:last-child)': {
          marginRight: '1rem',
        },
      },
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      fields: {
        width: 'auto',
      },
    },
  },
  { name: 'ModalForm' },
);

const Content = ({
  height = 'initial',
  size = 'tiny',
  centered = true,
  open,
  onOpen,
  trigger,
  title,
  onClose,
  children,
}) => {
  const classes = useStyles(height);

  return (
    <Modal
      size={size}
      dimmer="inverted"
      centered={centered}
      className={classes.root}
      open={open}
      trigger={trigger}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Modal.Header>
        <div className={classes.header}>
          <Image className={classes.close} onClick={onClose} src="/assets/close.svg" />
          <span className={classes.divider}>|</span>
          <span>{title}</span>
        </div>
      </Modal.Header>
      <Modal.Content className={classes.content}>{children}</Modal.Content>
    </Modal>
  );
};

const Fields = React.memo(({ children }) => {
  const classes = useStyles();
  return <div className={classes.fields}>{children}</div>;
});

const Actions = React.memo(({ children }) => {
  const classes = useStyles();
  return <div className={classes.actions}>{children}</div>;
});

export default { Content, Actions, Fields };
