import Colors from '@/constants/colors';

export const USER_ROUTES = {
  userContext: 'wave',
  root: 'rooms-grid',
  roomCalendar: 'room-calendar',
  reservationDetail: 'reservation-detail',
};

export const RESERVATION_TYPES = {
  OWNER: 'OWNER',
  GUEST_OF_OWNER: 'GUEST_OF_OWNER',
  RENT: 'RENT',
  HOLD: 'HOLD',
  UNSPECIFIED: 'UNSPECIFIED',
  ROOM_BLOCK: 'ROOM_BLOCK',
  ROOM_BUFFER: 'ROOM_BUFFER',
  RATES: 'RATES',
};

export const EVENT_TYPES = {
  RESERVATION: 'reservation',
  MAINTENANCE: 'maintenance',
  DAILY: 'daily',
  REQUEST: 'request',
  ON_PROPERTY: 'onProperty',
  FRONT_DESK_NOTIFICATION: 'frontDeskNotification',
  RATES: 'rates',
};

export const RESERVATION_PAYMENT_STATUS = {
  paid: { color: Colors.paid, text: '' },
  parcialPaid: { color: Colors.parcialPaid, text: '' },
  notPaid: { color: Colors.notPaid, text: '' },
  roomDisabled: { color: Colors.roomDisabled, text: '' },
  groupHold: { color: Colors.groupHold, text: '' },
};

export const RESERVATION_ROOM_TYPE = {
  reservationGroup: { icon: 'users', text: '' },
  onwerReservation: { icon: 'user circle', text: '' },
  doNotMove: { icon: 'lock', text: '' },
  roomRequested: { icon: 'flag', text: '' },
  multipleReservationRooms: { icon: 'plus', text: '' },
};

export const TOOLBAR_VIEWS = {
  WEEK: 'week',
  MONTH: 'month',
};
