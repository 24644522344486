import React from 'react';
import './Modal/QuickViewForm.css';

const ReservationRoomArrival = () => {
  return (
    <>
      <div
        className="res-table__row room__arrival"
        style={{ overflow: 'visible', paddingLeft: '8px' }}
      >
        <div className="room__type" style={{ width: '65%', justifyContent: 'space-evenly' }}>
          <button type="button" className="nav-btn-green" style={{ marginTop: '5px' }} id="id7ee">
            Waitlist
          </button>
          <form
            style={{ display: 'contents' }}
            id="id821"
            method="post"
            action="./rooms?2-1.IFormSubmitListener-navigationPanel-reservationQuickViewPanel-drawer-reservationRoom-roomsContainer-rooms-0-waitListForm"
          >
            <div
              style={{
                width: '0px',
                height: '0px',
                position: 'absolute',
                left: '-100px',
                top: '-100px',
                overflow: 'hidden',
              }}
              className="hidden-fields"
            >
              <input type="hidden" name="id821_hf_0" id="id821_hf_0" />
            </div>
            <div style={{ margin: '5px 5px 0' }}>
              <div>
                <div className="ui popup calendar">
                  <table className="ui celled center aligned unstackable table four column hour">
                    <tbody>
                      <tr>
                        <td className="link">12:00 AM</td>
                        <td className="link">1:00 AM</td>
                        <td className="link">2:00 AM</td>
                        <td className="link">3:00 AM</td>
                      </tr>
                      <tr>
                        <td className="link">4:00 AM</td>
                        <td className="link">5:00 AM</td>
                        <td className="link">6:00 AM</td>
                        <td className="link">7:00 AM</td>
                      </tr>
                      <tr>
                        <td className="link focus">8:00 AM</td>
                        <td className="link">9:00 AM</td>
                        <td className="link">10:00 AM</td>
                        <td className="link">11:00 AM</td>
                      </tr>
                      <tr>
                        <td className="link">12:00 PM</td>
                        <td className="link">1:00 PM</td>
                        <td className="link">2:00 PM</td>
                        <td className="link">3:00 PM</td>
                      </tr>
                      <tr>
                        <td className="link">4:00 PM</td>
                        <td className="link">5:00 PM</td>
                        <td className="link">6:00 PM</td>
                        <td className="link">7:00 PM</td>
                      </tr>
                      <tr>
                        <td className="link">8:00 PM</td>
                        <td className="link">9:00 PM</td>
                        <td className="link">10:00 PM</td>
                        <td className="link">11:00 PM</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <input
                  id="id7e4"
                  name="waitListTimeAsString"
                  type="text"
                  placeholder="Time"
                  disabled=""
                  value=""
                  readOnly
                ></input>
              </div>
            </div>
          </form>
        </div>
        <div>
          <form
            style={{ display: 'contents' }}
            id="id822"
            method="post"
            action="./rooms?2-1.IFormSubmitListener-navigationPanel-reservationQuickViewPanel-drawer-reservationRoom-roomsContainer-rooms-0-arrivalForm"
          >
            <div
              style={{
                width: '0px',
                height: '0px',
                position: 'absolute',
                left: '-100px',
                top: '-100px',
                overflow: 'hidden',
              }}
              className="hidden-fields"
            >
              <input type="hidden" name="id822_hf_0" id="id822_hf_0" />
            </div>
            <div style={{ margin: '0 12px' }}>
              <label>Arrival:</label>
              <div>
                <div className="ui popup calendar">
                  <table className="ui celled center aligned unstackable table four column hour">
                    <tbody>
                      <tr>
                        <td className="link">12:00 AM</td>
                        <td className="link">1:00 AM</td>
                        <td className="link">2:00 AM</td>
                        <td className="link">3:00 AM</td>
                      </tr>
                      <tr>
                        <td className="link">4:00 AM</td>
                        <td className="link">5:00 AM</td>
                        <td className="link">6:00 AM</td>
                        <td className="link">7:00 AM</td>
                      </tr>
                      <tr>
                        <td className="link focus">8:00 AM</td>
                        <td className="link">9:00 AM</td>
                        <td className="link">10:00 AM</td>
                        <td className="link">11:00 AM</td>
                      </tr>
                      <tr>
                        <td className="link">12:00 PM</td>
                        <td className="link">1:00 PM</td>
                        <td className="link">2:00 PM</td>
                        <td className="link">3:00 PM</td>
                      </tr>
                      <tr>
                        <td className="link">4:00 PM</td>
                        <td className="link">5:00 PM</td>
                        <td className="link">6:00 PM</td>
                        <td className="link">7:00 PM</td>
                      </tr>
                      <tr>
                        <td className="link">8:00 PM</td>
                        <td className="link">9:00 PM</td>
                        <td className="link">10:00 PM</td>
                        <td className="link">11:00 PM</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <input id="id7e3" name="arrivalTimeAsString" type="text" value="" readOnly></input>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReservationRoomArrival);
