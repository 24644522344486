import React from 'react';
import './Modal/QuickViewForm.css';
import ReservationRoomCard from './ReservationRoomCard';

const ReservationRooms = ({ reservation, onSave }) => {
  return (
    <>
      <div className="res-table menu-validate rooms">
        <div className="res-table__header">
          <h1>Rooms</h1>
          <button className="res-table__edit" type="button">
            Add Room +
          </button>
        </div>
        <div className="rooms-container">
          {reservation.reservationRoomList != null &&
            reservation.reservationRoomList.map((item, idx) => (
              <ReservationRoomCard
                reservationRoom={item}
                key={idx}
                onSave={onSave}
              ></ReservationRoomCard>
            ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(ReservationRooms);
