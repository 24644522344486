import React from 'react';
import { createUseStyles } from 'react-jss';
import { Icon } from 'semantic-ui-react';
import './Modal/QuickViewForm.css';
import { Link } from 'react-router-dom';
import { USER_ROUTES } from '@/constants/application.js';

const useStyles = createUseStyles(
  {
    header: (properties) => ({
      background: properties.backgroundColor,
      color: '#fff',
      display: 'flex',
      height: '45px',
      padding: '11px',
    }),
    close: {
      color: ' #fff',
      fontSize: '16px',
      position: 'absolute',
      right: '16px',
      cursor: 'pointer',
      '&:hover': {
        color: ' #fff',
      },
    },
    external: {
      color: ' #fff',
      fontSize: '15px',
      fontWeight: 'bold',
      position: 'absolute',
      right: '38px',
      top: '10px',
      cursor: 'pointer',
      '&:hover': {
        color: ' #fff',
      },
    },
    details: {
      color: ' #fff',
      fontSize: '13px',
      fontWeight: 'normal',
      position: 'absolute',
      right: '68px',
      top: '8px',
      border: '1px solid #fff',
      padding: '3px',
      paddingLeft: '8px',
      paddingRight: '8px',
      borderRadius: '6px',
      cursor: 'pointer',
      '&:hover': {
        color: ' #fff',
      },
    },
    reservation: {
      width: '110px',
    },
  },
  { name: 'QuickViewForm' },
);

const QuickViewHeader = ({ reservation, bgColor, onClose }) => {
  const classes = useStyles({ backgroundColor: bgColor != null ? bgColor : '' });

  const reservationDetailLink =
    '/' + USER_ROUTES.userContext + '/' + USER_ROUTES.reservationDetail + '/' + reservation.id;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.reservation}>#{reservation.id}</div>
        <div>
          {reservation.customer.lastName} {reservation.customer.firstName}
        </div>
        <Link className={classes.details} to={reservationDetailLink}>
          All Details
        </Link>
        <Link className={classes.external} to={reservationDetailLink} target="_blank">
          <Icon name="external alternate" />
        </Link>
        <a className={classes.close} onClick={onClose}>
          X
        </a>
      </div>
    </>
  );
};

export default React.memo(QuickViewHeader);
