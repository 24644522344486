const getPropertyAsOption = ({ id, name }) => ({
  key: id,
  text: `${name}`,
  value: id,
});

const getRatePlanAsOption = ({ id, code }) => ({
  key: id,
  text: `${code}`,
  value: id,
});

export default { getPropertyAsOption, getRatePlanAsOption };
