import React from 'react';
import './Modal/QuickViewForm.css';
import ReservationRoomAssign from './ReservationRoomAssign';
import ReservationRoomDates from './ReservationRoomDates';
import ReservationRoomCalendar from './ReservationRoomCalendar';

const ReservationRoomLeftCard = ({ reservationRoom, onSave }) => {
  return (
    <>
      <div className="room__card-left">
        <ReservationRoomAssign
          reservationRoom={reservationRoom}
          onSave={onSave}
        ></ReservationRoomAssign>
        <ReservationRoomDates reservationRoom={reservationRoom}></ReservationRoomDates>
        <ReservationRoomCalendar reservationRoom={reservationRoom}></ReservationRoomCalendar>
      </div>
    </>
  );
};

export default React.memo(ReservationRoomLeftCard);
