import React from 'react';
import ModalForm from '../ModalForm';
import useModalState from '../../hooks/useModalState';
import AssignRoomForm from '../ReservationRoom/AssignRoomForm';

const AssignRoomModal = ({ trigger, reservationRoom, onSave }) => {
  const { isOpen, onOpen, onClose } = useModalState();

  return (
    <ModalForm.Content
      height="auto"
      size="fullscreen"
      centered={false}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      title={'Assign Room'}
    >
      <AssignRoomForm onClose={onClose} onSave={onSave} reservationRoom={reservationRoom} />
    </ModalForm.Content>
  );
};

export default React.memo(AssignRoomModal);
