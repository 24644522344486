import React, { useState } from 'react';
import './Modal/QuickViewForm.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './ReservationRoomCalendar.css';

const ReservationRoomCalendar = () => {
  const [value, onChange] = useState([new Date(2025, 1, 10), new Date(2025, 2, 10)]);

  const isTileDisabled = ({ date }) => {
    //date.getDay() === 0;
    let startDate = value[0].getTime();
    let endDate = value[1].getTime();
    return !(date.getTime() >= startDate && date.getTime() <= endDate);
  };

  return (
    <Calendar
      onChange={onChange}
      value={value}
      showWeekNumbers={false}
      tileDisabled={isTileDisabled}
      className={'reservationroom'}
    />
  );
};

export default React.memo(ReservationRoomCalendar);
