import React from 'react';
import './Modal/QuickViewForm.css';
import ReservationRoomLeftCard from './ReservationRoomLeftCard';
import ReservationRoomRightCard from './ReservationRoomRightCard';

const ReservationRoomCard = ({ reservationRoom, onSave }) => {
  return (
    <>
      <div className="room">
        <ReservationRoomLeftCard
          reservationRoom={reservationRoom}
          onSave={onSave}
        ></ReservationRoomLeftCard>
        <ReservationRoomRightCard reservationRoom={reservationRoom}></ReservationRoomRightCard>
      </div>
    </>
  );
};

export default React.memo(ReservationRoomCard);
