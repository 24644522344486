import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { sessionSelector } from '@/store/selector';
import Selects from '@/helpers/selects';
import { listRatePlan } from '@/api/reservationAPI';

const useStyles = createUseStyles(
  {
    root: { height: 'fit-content' },
  },
  { name: 'RatePlanDropdown' },
);

const RatePlanDropdown = ({ onChange }) => {
  const classes = useStyles();
  const session = sessionSelector();
  const user = session.user;
  const [ratePlanList, setRatePlanList] = useState([]);

  const RATEPLANS = ratePlanList.map((ratePlan) => ({
    ...Selects.getRatePlanAsOption(ratePlan),
  }));

  useEffect(() => {
    let request = {
      propertyId: user?.selectedProperty,
    };
    listRatePlan(request)
      .then((response) => {
        setRatePlanList(response.data);
      })
      .catch(onError);
  }, []);

  const onError = (e) => {
    console.log(e);
  };

  return <Dropdown className={classes.root} options={RATEPLANS} selection onChange={onChange} />;
};

export default React.memo(RatePlanDropdown);
