export const compareApiCall = (objToCompare1, objToCompare2) => {
  if (
    objToCompare1.callApiStatus == objToCompare2.callApiStatus &&
    objToCompare1.callApiAction == objToCompare2.callApiAction &&
    objToCompare1.callApiEntity == objToCompare2.callApiEntity
  ) {
    return true;
  }
  return false;
};
