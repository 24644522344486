import React from 'react';
import './Modal/QuickViewForm.css';

const ReservationRoomStatus = ({reservationRoom}) => {
  return (
    <>
        <div className="room__status">
            <div className="rooms-status-content">Status: <span><span>{reservationRoom.status}</span></span></div>
        </div>      
    </>
  );
};

export default React.memo(ReservationRoomStatus);
