import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import WaveLayout from '../layouts/WaveLayout';
import RoomsGridView from '@/views/RoomsGridView';
import RoomCalendarView from '@/views/RoomCalendarView';
import { prepareApp } from '@/api/sessionAPI';
import { Loader } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { prepareApp as prepareAppSession } from '@/features/session/sessionSlice';
import { USER_ROUTES } from '@/constants/application.js';
import ReservationDetailView from '@/views/ReservationDetailView';

const WaveRoutes = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    prepareApp().then((res) => {
      setLoading(false);
      dispatch(prepareAppSession(res.data));
    });
  }, []);

  if (loading) {
    return (
      <Loader id="full-screen-loader" size="big" indeterminate>
        Loading resources...
      </Loader>
    );
  }

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <WaveLayout />
          </ProtectedRoute>
        }
      >
        <Route path={USER_ROUTES.root} element={<RoomsGridView />} />
        <Route path={USER_ROUTES.roomCalendar} element={<RoomCalendarView />} />
        <Route path={USER_ROUTES.reservationDetail + '/:id'} element={<ReservationDetailView />} />
      </Route>
    </Routes>
  );
};

export default React.memo(WaveRoutes);
