import React from 'react';
import './Modal/QuickViewForm.css';
import ReservationRoomStatus from './ReservationRoomStatus';
import ReservationRoomKeyCode from './ReservationRoomKeyCode';
import ReservationRoomArrival from './ReservationRoomArrival';
import ReservationRoomAmounts from './ReservationRoomAmounts';
import ReservationRoomActions from './ReservationRoomActions';

const ReservationRoomRightCard = ({reservationRoom}) => {
  return (
    <>
        <div className="room__card-right">  
            <ReservationRoomStatus reservationRoom={reservationRoom}></ReservationRoomStatus>
            <ReservationRoomKeyCode reservationRoom={reservationRoom}></ReservationRoomKeyCode>
            <ReservationRoomArrival reservationRoom={reservationRoom}></ReservationRoomArrival>
            <ReservationRoomAmounts reservationRoom={reservationRoom}></ReservationRoomAmounts>
            <ReservationRoomActions reservationRoom={reservationRoom}></ReservationRoomActions>
        </div>
    </>
  );
};

export default React.memo(ReservationRoomRightCard);
