import { createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '@/api/api';

const initialState = {
  reservationDetailList: {},
};

const getReservationDetail = (reservationDetailList, reservationId) => {
  return reservationDetailList[reservationId] || {};
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    loadReservation: (state, data) => {
      let reservationDetail = getReservationDetail(
        state.reservationDetailList,
        data.payload.reservationId,
      );

      reservationDetail.loadReservationStatus = API_STATUS.PROCESSING;
      reservationDetail.loadReservationError = undefined;

      state.reservationDetailList[data.payload.reservationId] = reservationDetail;
    },
    setReservation: (state, data) => {
      let reservationDetail = Object.assign({}, data.payload);
      reservationDetail.loadReservationStatus = API_STATUS.IDLE;
      state.reservationDetailList[data.payload.id] = reservationDetail;
    },
    callApi: (state, data) => {
      let reservationDetail = getReservationDetail(
        state.reservationDetailList,
        data.payload.reservationId,
      );

      reservationDetail.callApiStatus = API_STATUS.PROCESSING;
      reservationDetail.callApiAction = data.payload.callApiAction;
      reservationDetail.callApiEntity = data.payload.callApiEntity;
      reservationDetail.savingRatePlanError = undefined;

      state.reservationDetailList[data.payload.reservationId] = reservationDetail;
    },
    callApiError: (state, data) => {
      let reservationDetail = getReservationDetail(
        state.reservationDetailList,
        data.payload.reservationId,
      );

      reservationDetail.callApiStatus = API_STATUS.ERROR;
      reservationDetail.callApiAction = data.payload.callApiAction;
      reservationDetail.callApiEntity = data.payload.callApiEntity;
      reservationDetail.savingRatePlanError = data.payload.error;

      state.reservationDetailList[data.payload.reservationId] = reservationDetail;
    },
    callApiSuccess: (state, data) => {
      let reservationDetail = getReservationDetail(
        state.reservationDetailList,
        data.payload.reservationId,
      );

      reservationDetail.callApiStatus = API_STATUS.SUCCESS;
      reservationDetail.callApiAction = data.payload.callApiAction;
      reservationDetail.callApiEntity = data.payload.callApiEntity;
      reservationDetail.savingRatePlanError = undefined;

      state.reservationDetailList[data.payload.reservationId] = reservationDetail;
    },
    cleanReservation: (state) => {
      state.reservationDetailList = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  callApi,
  callApiError,
  callApiSuccess,
  cleanReservation,
  loadReservation,
  setReservation,
} = reservationSlice.actions;

export default reservationSlice.reducer;
