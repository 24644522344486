import { useEffect, useRef } from 'react';
import { Transition } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    root: (properties) => ({
      width: properties.side === 'up' ? '100% !important' : '50% !important',
      right: properties.side === 'right' ? '0 !important' : '',
      left: properties.side === 'left' || properties.side === 'up' ? '0 !important' : '',
      height: properties.height + ' !important',
      position: 'fixed',
      top: properties.side === 'up' ? '' : '0',
      bottom: properties.side === 'up' ? '0' : '',
      background: '#fff',
      zIndex: '99',
      borderLeft: '1px solid #cacaca',
      [`@media (max-width: 800px)`]: {
        width: '100% !important',
      },
      overflowY: 'scroll',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
    }),
  },
  { name: 'ModalSlideForm' },
);

const Content = ({ visible, side, height, onBlur, children }) => {
  const classes = useStyles({ side: side, height: height });
  const divReference = useRef(null);

  let animation = '';
  switch (side) {
    case 'right':
      animation = 'slide left';
      break;
    case 'left':
      animation = 'slide right';
      break;
    case 'up':
      animation = 'slide up';
      break;
  }

  useEffect(() => {
    if (visible) {
      divReference.current.focus();
    }
  }, [visible]);

  return (
    <div tabIndex={-1} onBlur={onBlur} ref={divReference}>
      <Transition visible={visible} animation={animation} duration={600}>
        <div className={classes.root}>
          <div> {children}</div>
        </div>
      </Transition>
    </div>
  );
};

export default { Content };
