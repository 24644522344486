import React from 'react';
import './ReservationRoom.css';

const AssignItem = ({ item, selectedRoom, onRoomSlected }) => {
  let cssClass = item.id === selectedRoom ? 'unit unit--active' : 'unit';

  return (
    <div className={cssClass} onClick={() => onRoomSlected(item)}>
      <div>
        <p className="unit__room-number">
          <span>
            {item.roomNumber} ({item.status})
          </span>
        </p>
        <p className="unit__room-desc">
          <span>{item.roomType}</span>
        </p>
      </div>
    </div>
  );
};

export default React.memo(AssignItem);
