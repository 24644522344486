import React from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import ImageButton from './Buttons/ImageButton';
import EllipsisText from './EllipsisText';
import PropertyDropdown from './Dropdowns/PropertyDropdown';
import Colors from '@/constants/colors';
import { Media } from '../media';
import { Link } from 'react-router-dom';
import { sessionSelector } from '@/store/selector';
import { useDispatch } from 'react-redux';
import { changeProperty } from '@/features/session/sessionSlice';
import SearchField from '@/components/Fields/SearchField';
import { search } from '@/api/reservationAPI';
import Breakpoints from '@/constants/breakpoints';

const useStyles = createUseStyles(
  {
    root: {
      alignItems: 'center',
      [`@media (max-width: ${Breakpoints.md}px)`]: {
        '& .item': {
          paddingLeft: '0 !important',
        },
      },
    },
    welcome: {
      fontWeight: 'normal',
      color: Colors.grayThree,
    },
  },
  { name: 'TopPanel' },
);

const TopPanel = ({ showMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const session = sessionSelector();
  const user = session.user;

  const onChangePropertySelected = (_, { value }) => {
    const data = {
      propertyId: value,
    };
    dispatch(changeProperty(data));
  };

  return (
    <Menu className={classes.root} secondary>
      {!!showMenu && (
        <Menu.Item>
          <ImageButton style={{ padding: 5 }} src="/assets/menu.svg" onClick={showMenu} />
        </Menu.Item>
      )}
      <Media greaterThanOrEqual="md">
        <Menu.Item>
          <EllipsisText>
            <h3 className={classes.welcome}>
              Welcome, <strong>{user?.firstName}</strong>
            </h3>
          </EllipsisText>
        </Menu.Item>
      </Media>
      <Menu.Menu position="right">
        <Menu.Item>
          <SearchField apiService={search} />
          <PropertyDropdown onChange={onChangePropertySelected} />
          <Dropdown item icon={{ name: 'user', circular: true, inverted: true }}>
            <Dropdown.Menu>
              <Link to="/sign-out">
                <Dropdown.Item content="Sign out" />
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default React.memo(TopPanel);
