import React, { useState } from 'react';
import NoteModal from './NoteModal';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, Icon } from 'semantic-ui-react';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import { deleteReservationNote } from '@/api/reservationAPI';
import './Notes.css';

const deleteButton = ({ onClick }) => <Icon name="trash" className="note-edit" onClick={onClick} />;

const Notes = ({ reservation }) => {
  const [noteList, setNoteList] = useState(reservation.noteList);

  const onClickDeleteNote = (note) => {
    const promise = new Promise((resolve) => {
      deleteReservationNote(note).then(() => {
        setNoteList((prevNoteList) => prevNoteList.filter((prevNote) => prevNote.id != note.id));
        resolve();
      });
      resolve();
    });

    return promise;
  };

  const onSaveNote = (newNote) => {
    setNoteList((prevNoteList) => [...prevNoteList, newNote]);
  };

  const onUpdateNote = (note) => {
    setNoteList((prevNoteList) =>
      prevNoteList.map((prevNote) => (prevNote.id == note.id ? note : prevNote)),
    );
  };

  return (
    <>
      <div className="res-table menu-validate rooms">
        <div className="res-table__header">
          <h1>Notes</h1>
          <NoteModal
            trigger={
              <button className="res-table__edit" type="button">
                Add Note +
              </button>
            }
            item={{
              note: null,
              housekeepingRelated: false,
            }}
            onSave={onSaveNote}
            reservation={reservation}
          />
        </div>
      </div>
      <div className="notes-contaier">
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell>Date</TableHeaderCell>
              <TableHeaderCell>By</TableHeaderCell>
              <TableHeaderCell>Note</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {noteList !== null &&
              noteList.length > 0 &&
              noteList.map((item, idx) => (
                <Table.Row key={idx}>
                  <Table.Cell>
                    <NoteModal
                      trigger={<Icon name="pencil" className="note-edit" />}
                      item={item}
                      onSave={onUpdateNote}
                      reservation={reservation}
                    />
                    <ConfirmationModal
                      header="Delete Note"
                      trigger={deleteButton}
                      onConfirm={() => onClickDeleteNote(item)}
                    >
                      Are you sure you want to delete the note ?
                    </ConfirmationModal>
                  </Table.Cell>
                  <Table.Cell>{item.date}</Table.Cell>
                  <Table.Cell>{item.by}</Table.Cell>
                  <Table.Cell>{item.note}</Table.Cell>
                </Table.Row>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default React.memo(Notes);
