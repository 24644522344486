import axios from 'axios';
import { store } from '@/store/store';

export const API_STATUS = {
  IDLE: 'IDLE',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const API_ACTION = {
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const API_ENTITY = {
  RATEPLAN: 'RATEPLAN',
  NOTE: 'NOTE',
};

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: '/api/',
};

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);

axiosInstance.interceptors.request.use(
  async (config) => {
    const session = store.getState().session;
    if (session?.token) {
      config.headers['Waveweb-Token'] = session.token;
    }

    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;

    if (error.response?.status === 401) {
      window.location = '/unauthorized';
      return axios(config);
    }

    if (error.response?.status === 404) {
      window.location = '/notfound';
      return axios(config);
    }

    return Promise.reject(error);
  },
);

// Main api function
const api = (axios) => {
  return {
    get: function (url, config) {
      //const [{ data: getData, loading: getLoading, error: getError }] =
      //return useAxios(url);
      if (config) {
        return axios.get(url, config);
      } else {
        return axios.get(url);
      }
    },
    /*delete: function (url, config) {
      return axios.delete(url);
    },*/
    post: (url, body) => {
      return axios.post(url, body);
      /*return useAxios(
        {
          url: '/api/' + url,
          method: 'POST',
        },
        { manual: true },
      );*/
    },
    delete: (url, body) => {
      return axios.delete(url, body);
      /*return useAxios(
        {
          url: '/api/' + url,
          method: 'POST',
        },
        { manual: true },
      );*/
    },
    /*patch: function (url, body, config) {
      return axios.patch(url, body, config);
    },
    put: function (url, body, config) {
      return axios.put(url, body, config);
    },*/
  };
};
export default api(axiosInstance);
