import React from 'react';
import ModalForm from '../ModalForm';
import useModalState from '../../hooks/useModalState';
import NoteForm from '../Notes/NoteForm';

const NoteModal = ({ trigger, item, reservation, onSave }) => {
  const { isOpen, onOpen, onClose } = useModalState();

  return (
    <ModalForm.Content
      height="auto"
      size="small"
      centered={false}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      title={'Reservation Note'}
    >
      <NoteForm onClose={onClose} onSave={onSave} item={item} reservation={reservation} />
    </ModalForm.Content>
  );
};

export default React.memo(NoteModal);
