import { takeLatest, put, call } from 'redux-saga/effects';
import { loginUser, setSession, loginUserFailure } from '@/features/session/sessionSlice';
import { login } from '@/api/sessionAPI';

// Saga to handle user login
function* handleLogin(action) {
  try {
    const credentials = action.payload;

    // Call the authenticateUser API function with the username and password
    const session = yield call(login, credentials);
    // Dispatch the loginUserSuccess action with the user data

    yield put(setSession(session.data));
  } catch (error) {
    // If there is an error, dispatch the loginUserFailure action with the error message
    yield put(loginUserFailure(error.message));
  }
}

// Watcher saga
function* authSaga() {
  // Listen for the loginUser action and call the handleLogin saga when dispatched
  yield takeLatest(loginUser.type, handleLogin);
}

export default authSaga;
