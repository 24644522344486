import React from 'react';
import ModalForm from '../ModalForm';
import useModalState from '../../hooks/useModalState';
import ChangeRatePlanForm from './ChangeRatePlanForm';

const ChangeRatePlanModal = ({ trigger, reservation }) => {
  const { isOpen, onOpen, onClose } = useModalState();

  return (
    <ModalForm.Content
      height="auto"
      size="small"
      centered={false}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      title={
        'Change Rate Plan for Reservation ' + (reservation != null ? '#' + reservation.id : '')
      }
    >
      <ChangeRatePlanForm onClose={onClose} reservation={reservation} />
    </ModalForm.Content>
  );
};

export default React.memo(ChangeRatePlanModal);
