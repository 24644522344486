import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { sessionSelector } from '@/store/selector';
import { ping } from '@/api/sessionAPI';

const ProtectedRoute = ({ children }) => {
  const session = sessionSelector();
  const location = useLocation();
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (!session.token) {
      // return <Navigate to="/unauthorized" state={{ callbackUrl: location.pathname }} replace />;
      navigate('/unauthorized', {
        replace: true,
        state: { callbackUrl: location.pathname },
      });
    } else {
      ping()
        .then(() => setAuthorized(true))
        .catch(() => {
          navigate('/unauthorized', {
            replace: true,
            state: { callbackUrl: location.pathname },
          });
        });
    }
  }, []);

  return authorized && children;
};
export default React.memo(ProtectedRoute);
