import React from 'react';
import AssignRoomModal from './ReservationRoom/AssignRoomModal';
import './Modal/QuickViewForm.css';

const ReservationRoomAssign = ({ reservationRoom, onSave }) => {
  return (
    <>
      <form className="room__header">
        <div className="room__card-number">
          <p>
            <span>1</span>.)
          </p>
        </div>
        <div className="room__name">
          <span>
            {reservationRoom.lastName} {reservationRoom.firstName}
          </span>{' '}
        </div>
      </form>

      <div className="res-table__row">
        <div className="room__type" id="id7e6">
          <span>{reservationRoom.roomType}</span>
        </div>
        <div
          className="room__assign room__assign--flagged"
          data-overlay="overlay-assign"
          id="id7ea"
        >
          <AssignRoomModal
            trigger={
              <div>
                {reservationRoom.room.id != null && (
                  <div>
                    {reservationRoom.room.roomNumber} ({reservationRoom.room.status})
                    <a className="unassign-room" id="id7e9" href="javascript:;">
                      <i className="icon-red-ex"></i>
                    </a>
                  </div>
                )}
              </div>
            }
            reservationRoom={reservationRoom}
            onSave={onSave}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ReservationRoomAssign);
