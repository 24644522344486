import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { cleanSession } from '@/features/session/sessionSlice';
import { cleanReservation } from '@/features/reservation/reservationSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout } from '@/api/sessionAPI';

const cleanStore = () => (dispatch) =>
  Promise.all([dispatch(cleanSession()), dispatch(cleanReservation())]);

const SignOutView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = bindActionCreators({ cleanStore }, dispatch);
  const signOut = () => {
    navigate('/login', {
      state: {
        callbackUrl: location?.state?.callbackUrl ? location?.state?.callbackUrl : undefined,
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      logout().then(() => {
        actions.cleanStore().then(signOut);
      });
    }, 1500);
  }, []);

  return (
    <Loader id="full-screen-loader" size="big" indeterminate>
      Signing out...
    </Loader>
  );
};

export default React.memo(SignOutView);
