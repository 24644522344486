import { takeLatest, put, call } from 'redux-saga/effects';
import {
  callApi,
  callApiSuccess,
  callApiError,
  loadReservation,
  setReservation,
} from '@/features/reservation/reservationSlice';
import { updateReservationRatePlan, searchReservationData } from '@/api/reservationAPI';
import { API_ENTITY } from '@/api/api';

// Saga to handle user login
function* handleCallApi(action) {
  try {
    const reservationData = action.payload;
    switch (reservationData.callApiEntity) {
      case API_ENTITY.RATEPLAN:
        yield call(updateReservationRatePlan, reservationData);
        break;
      case API_ENTITY.NOTE:
        yield call(updateReservationRatePlan, reservationData);
        break;
    }
    yield put(callApiSuccess(reservationData));
    yield* handleUpdateReservation({ payload: { reservationId: action.payload.reservationId } });
  } catch (error) {
    yield put(callApiError(error.message));
  }
}

function* handleUpdateReservation(action) {
  try {
    let reservationData = action.payload;
    reservationData = yield call(searchReservationData, reservationData);
    yield put(setReservation(reservationData.data));
  } catch (error) {
    console.log(error);
  }
}

// Watcher saga
function* reservationSaga() {
  yield takeLatest(callApi.type, handleCallApi);
  yield takeLatest(loadReservation.type, handleUpdateReservation);
}

export default reservationSaga;
