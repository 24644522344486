import React from 'react';
import './Modal/QuickViewForm.css';

const ReservationRoomKeyCode = () => {
  return (
    <>
      <div className="res-table__row room__keycode room__keycode--active">
        <i className="icon-phone"></i>
      </div>
    </>
  );
};

export default React.memo(ReservationRoomKeyCode);
