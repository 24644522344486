import React from 'react';
import SlideForm from '../SlideForm';
import QuickViewForm from './QuickViewForm';
import { reservationSelector } from '@/store/selector';
import { Dimmer, Loader } from 'semantic-ui-react';
import { API_STATUS } from '@/api/api';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    dimmer: {
      height: '100px',
      display: 'contents',
    },
  },
  { name: 'EllipsisText' },
);

const QuickViewModal = ({ open, onClose, reservationId, bgColor }) => {
  const classes = useStyles();
  const reservationStore = reservationSelector();

  const loading =
    reservationStore.reservationDetailList[reservationId]?.loadReservationStatus ==
    API_STATUS.PROCESSING;

  const visible =
    reservationStore.reservationDetailList[reservationId]?.loadReservationStatus == API_STATUS.IDLE;

  return (
    <SlideForm.Content visible={open} side="right" height="100%">
      <Dimmer.Dimmable dimmed={loading} className={classes.dimmer}>
        <Dimmer active={loading} inverted>
          <Loader inverted content="Loading reservation..." />
        </Dimmer>
        {visible && (
          <QuickViewForm
            onClose={onClose}
            reservation={reservationStore.reservationDetailList[reservationId]}
            bgColor={bgColor}
          />
        )}
      </Dimmer.Dimmable>
    </SlideForm.Content>
  );
};

export default React.memo(QuickViewModal);
