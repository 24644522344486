import React from 'react';
import './Modal/QuickViewForm.css';

const ReservationRoomActions = () => {
  return (
    <>
      <div
        className="res-table__row room__edit-rate"
        data-overlay="overlay-avg-rate"
        style={{ height: '51px !important' }}
      >
        <div id="id7eb">
          <button className="tiny ui labeled icon button">
            <i className="icon">
              <i className="fas fa-pen"></i>
            </i>
            Edit
          </button>
        </div>
        <div id="id7ec">
          <p>
            <button className="tiny ui labeled icon button">
              <i className="icon">
                <i className="fas fa-broom"></i>
              </i>
              HK
            </button>
          </p>
        </div>
        <div id="id7ed">
          <p>
            <button className="tiny ui labeled icon button">
              <i className="icon">
                <i className="fas fa-plus"></i>
              </i>
              Add-ons
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReservationRoomActions);
