import React from 'react';
import { Icon } from 'semantic-ui-react';
import './Modal/QuickViewForm.css';
import ChangeRatePlanModal from './Modal/ChangeRatePlanModal';

const ReservationInfo = ({ reservation }) => {
  return (
    <>
      <div className="drawer-quick-view__form form">
        <div className="reservations-content">
          <div className="res-table__header">
            <div className="reservation__addon_quickview"></div>
            <div className="reservation-nav__tab"></div>
          </div>
          <div className="res-table__row quick-view-info-first-row">
            <div className="res-table__input-container">
              <div className="res-table__label">
                <p>Name</p>
              </div>
              <div className="reservation-span">
                {reservation.customer.lastName} {reservation.customer.firstName}
              </div>
            </div>

            <div className="res-table__input-container">
              <div className="res-table__label">
                <p>Mobile</p>
              </div>
              <div className="reservation-span">{reservation.customer.mobile}</div>
            </div>

            <div className="res-table__input-container">
              <div className="res-table__label">
                <p>Email</p>
              </div>
              <div className="reservation-span">{reservation.customer.email}</div>
            </div>
          </div>
          <div className="res-table__row quick-view-info-first-row">
            <div className="res-table__input-container">
              <div className="res-table__label">
                <p>Gross total</p>
              </div>
              <div className="reservation-span">$ {reservation.total}</div>
            </div>

            <div className="res-table__input-container">
              <div className="res-table__label">
                <p>Paid</p>
              </div>
              <div className="reservation-span">$ {reservation.paid}</div>
            </div>

            <div className="res-table__input-container">
              <div className="res-table__label">
                <p>Balance due</p>
              </div>
              <div className="reservation-span">$ {reservation.balance}</div>
              <a className=" request-due request-due-zero" id="id16cf">
                <i className="icon credit card"></i>
              </a>
            </div>
          </div>
          <div className="res-table__row quick-view-info-second-row">
            <div className="res-table__input-container res-table__input-container-midled">
              <div className="res-table__label">
                <p>Cxl Pol</p>
              </div>
              <div className="reservation-span">{reservation.autoRefundCriteria.text}</div>
            </div>

            <div className="res-table__input-container res-table__input-container-midled">
              <div className="res-table__label">
                <p>Rate Plan</p>
              </div>
              <div className="reservation-span">
                {reservation.ratePlan.code}
                <ChangeRatePlanModal
                  trigger={<Icon name="pencil" className="rate-plan-edit" />}
                  reservation={reservation}
                />
              </div>
            </div>

            <div className="res-table__input-container res-table__input-container-midled">
              <div className="res-table__label">
                <p>Group</p>
              </div>
              <div className="reservation-span">{reservation.group.name}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReservationInfo);
