import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import { USER_ROUTES } from '@/constants/application';
import BasicLayout from '../layouts/BasicLayout';
import { useDispatch } from 'react-redux';
import { sessionSelector } from '@/store/selector';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginUser } from '@/features/session/sessionSlice';

const useStyles = createUseStyles(
  {
    logo: {
      backgroundImage: "url('/assets/dark_logo.png')",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50%',
      height: 70,
      marginBottom: '2rem',
    },
    submit: {
      width: '100%',
      color: `${Colors.whiteOne} !important`,
      background: `${Colors.greenOne} !important`,
    },
    forgotPassword: {
      display: 'block',
      cursor: 'pointer',
      textAlign: 'center',
      margin: '1rem 0',
    },
  },
  { name: 'LoginView' },
);

const LoginView = () => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const session = sessionSelector();
  const [errors, setErrors] = useState({ email: null, password: null });
  const [failed, setFailed] = useState(false);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const { email, password } = credentials;

  const onSubmit = () => {
    setLoading(true);
    dispatch(loginUser(credentials));
  };

  useEffect(() => {
    if (session.loginError) {
      onError();
    }
  }, [session.loginError]);

  const onError = () => {
    setLoading(false);
    setFailed(true);
  };

  const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    setCredentials({ ...credentials, [id]: value.trim() });
  };

  useEffect(() => {
    if (session?.token) {
      if (location?.state?.callbackUrl) {
        navigate(location?.state?.callbackUrl);
      } else {
        navigate('/' + USER_ROUTES.userContext + '/' + USER_ROUTES.root);
      }
    }
  }, [session]);

  return (
    <BasicLayout>
      <>
        <div className={classes.logo} />
        <Form loading={loading} onSubmit={onSubmit}>
          <Form.Field
            id="email"
            control={Input}
            placeholder="Username"
            type="text"
            value={email}
            onChange={onChange}
            error={errors.email}
          />
          <Form.Field
            id="password"
            control={Input}
            placeholder="Password"
            type="password"
            value={password}
            onChange={onChange}
            error={errors.password}
          />
          <Message
            header="Invalid Credentials"
            content="Please, enter a correct username and password."
            visible={!!failed}
            error
          />
          <Button className={classes.submit} type="submit">
            Submit
          </Button>
        </Form>
      </>
    </BasicLayout>
  );
};

export default React.memo(LoginView);
