// sagas/index.js
import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import propertySaga from './propertySaga';
import reservationSaga from './reservationSaga';

// Root saga
function* rootSaga() {
  yield all([authSaga(), propertySaga(), reservationSaga()]);
}

export default rootSaga;
