import React from 'react';
import ModalForm from '../ModalForm';
import { Form, Button, TextArea, Checkbox } from 'semantic-ui-react';
import { useState } from 'react';
import Joi from 'joi';
import ValidationHelper from '@/helpers/validationHelper';
import { createReservationNote, updateReservationNote } from '@/api/reservationAPI';

const modelSchema = Joi.object({
  note: Joi.string().required().messages({
    'string.base': 'Note is mandatory',
    'any.required': 'Note is mandatory',
    'string.empty': 'Note is mandatory',
  }),
});

const NoteForm = ({ onClose, item, reservation, onSave }) => {
  const [model, setModel] = useState({
    note: item.note,
    housekeepingRelated: item.housekeepingRelated,
  });
  const { note, housekeepingRelated } = model;
  const [errors, setErrors] = useState({
    note: null,
    housekeepingRelated: null,
  });

  const onChange = (_event, { id, value }) => {
    if (id === 'housekeepingRelated') {
      value = housekeepingRelated === undefined ? true : !housekeepingRelated;
      setModel({ ...model, [id]: value });
    } else {
      setErrors({ ...errors, [id]: null });
      setModel({ ...model, [id]: value });
    }
  };

  const onError = (e) => {
    console.log(e);
  };

  const onSaveClicked = () => {
    ValidationHelper.validateSchema(modelSchema, model)
      .then(() => {
        let reservationNote = {
          id: item.id,
          reservationId: reservation.id,
          note: note,
          housekeepingRelated: housekeepingRelated,
        };
        if (item != null && item.id != null) {
          updateReservationNote(reservationNote)
            .then((response) => {
              onSave(response.data);
              onClose();
            })
            .catch(onError);
        } else {
          createReservationNote(reservationNote)
            .then((response) => {
              onSave(response.data);
              onClose();
            })
            .catch(onError);
        }
      })
      .catch((schemaErrors) => {
        setErrors(schemaErrors);
      });
  };

  return (
    <Form>
      <Form.Group>
        <Form.Field
          id="note"
          control={TextArea}
          label="Note"
          type="text"
          onChange={onChange}
          value={note}
          error={errors.note}
          width={16}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field
          id="housekeepingRelated"
          control={Checkbox}
          label="Housekeeping Related"
          onChange={onChange}
          checked={housekeepingRelated}
          error={errors.housekeepingRelated}
          width={16}
        />
      </Form.Group>

      <ModalForm.Actions>
        <Button onClick={onSaveClicked} type="submit" primary>
          Save
        </Button>
        <Button color="red" onClick={onClose}>
          Cancel
        </Button>
      </ModalForm.Actions>
    </Form>
  );
};

export default React.memo(NoteForm);
