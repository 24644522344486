import React, { useState, useEffect } from 'react';
import ModalForm from '../ModalForm';
import { Form, Button, Input } from 'semantic-ui-react';
import Joi from 'joi';
import ValidationHelper from '@/helpers/validationHelper';
import RatePlanDropdown from '@/components/Dropdowns/RatePlanDropdown';
import { useDispatch } from 'react-redux';
import { callApi } from '@/features/reservation/reservationSlice';
import { API_STATUS, API_ACTION, API_ENTITY } from '@/api/api';
import { compareApiCall } from '@/helpers/apiHelper';

const modelSchema = Joi.object({
  newRatePlanId: Joi.number().required().messages({
    'number.base': 'New Rate Plan is mandatory',
    'any.required': 'New Rate Plan is mandatory',
    'number.empty': 'New Rate Plan is mandatory',
  }),
});

const ratePlanApi = {
  callApiAction: API_ACTION.UPDATE,
  callApiEntity: API_ENTITY.RATEPLAN,
};

const ChangeRatePlanForm = ({ onClose, reservation }) => {
  const dispatch = useDispatch();
  const [model, setModel] = useState({
    newRatePlanId: null,
  });

  const [errors, setErrors] = useState({
    newRatePlanId: null,
  });

  const onChange = (_event, { value }) => {
    setErrors({ ...errors, newRatePlanId: null });
    setModel({ ...model, newRatePlanId: value });
  };

  const onError = (e) => {
    console.log(e);
  };

  useEffect(() => {
    if (compareApiCall({ ...ratePlanApi, callApiStatus: API_STATUS.SUCCESS }, reservation)) {
      onClose();
    }
    if (compareApiCall({ ...ratePlanApi, callApiStatus: API_STATUS.ERROR }, reservation)) {
      onError(reservation.savingRatePlanError);
    }
  }, [reservation]);

  const onSaveClicked = () => {
    ValidationHelper.validateSchema(modelSchema, model)
      .then(() => {
        let request = {
          reservationId: reservation.id,
          newRatePlanId: model.newRatePlanId,
        };
        dispatch(callApi({ ...request, ...ratePlanApi }));
      })
      .catch((schemaErrors) => {
        setErrors(schemaErrors);
      });
  };

  return (
    <Form
      loading={compareApiCall(
        { ...ratePlanApi, callApiStatus: API_STATUS.PROCESSING },
        reservation,
      )}
    >
      <Form.Group>
        <Form.Field
          id="currentRatePLan"
          control={Input}
          label="Current Rate Plan"
          type="text"
          onChange={onChange}
          value={reservation.ratePlan.code}
          error={errors.currentRatePLan}
          disabled={true}
          width={8}
        />
        <Form.Field
          id="newRatePlanId"
          control={RatePlanDropdown}
          label="New Rate Plan"
          clearable
          width={8}
          onChange={onChange}
          error={errors.newRatePlanId}
        />
      </Form.Group>

      <ModalForm.Actions>
        <Button color="red" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSaveClicked} type="submit" primary>
          Save
        </Button>
      </ModalForm.Actions>
    </Form>
  );
};

export default React.memo(ChangeRatePlanForm);
