import api from './api';

const URLS = {
  search: 'search',
  searchReservationData: 'searchReservationData',
  listRatePlan: 'listRatePlan',
  updateReservationRatePlan: 'updateReservationRatePlan',
  createReservationNote: 'createReservationNote',
  updateReservationNote: 'updateReservationNote',
  deleteReservationNote: 'deleteReservationNote',
  listAvailableRooms: 'listAvailableRooms',
  updateRatesForRoomAssign: 'updateRatesForRoomAssign',
  assignRoom: 'assignRoom',
};

export const search = ({ query, category }) => {
  return api.post(URLS.search, { query, category });
};

export const searchReservationData = (request) => {
  return api.post(URLS.searchReservationData, request);
};

export const listRatePlan = (request) => {
  return api.post(URLS.listRatePlan, request);
};

export const updateReservationRatePlan = (request) => {
  return api.post(URLS.updateReservationRatePlan, request);
};

export const createReservationNote = (request) => {
  return api.post(URLS.createReservationNote, request);
};

export const updateReservationNote = (request) => {
  return api.post(URLS.updateReservationNote, request);
};

export const deleteReservationNote = (request) => {
  return api.post(URLS.deleteReservationNote, request);
};

export const listAvailableRooms = (request) => {
  return api.post(URLS.listAvailableRooms, request);
};

export const updateRatesForRoomAssign = (request) => {
  return api.post(URLS.updateRatesForRoomAssign, request);
};

export const assignRoom = (request) => {
  return api.post(URLS.assignRoom, request);
};
