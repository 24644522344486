import React from 'react';
import './QuickViewForm.css';
import QuickViewHeader from '../QuickViewHeader';
import ReservationRooms from '../ReservationRooms';
import ReservationInfo from '../ReservationInfo';
import Notes from '../Notes/Notes';

const QuickViewForm = ({ reservation, bgColor, onClose }) => {
  return (
    <>
      <QuickViewHeader
        reservation={reservation}
        bgColor={bgColor}
        onClose={onClose}
      ></QuickViewHeader>
      <ReservationInfo reservation={reservation}></ReservationInfo>
      <ReservationRooms reservation={reservation}></ReservationRooms>
      <Notes reservation={reservation}></Notes>
    </>
  );
};

export default React.memo(QuickViewForm);
