import { Link } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';
import { useLocation } from 'react-router-dom';

const UnauthorizedView = () => {
  const location = useLocation();
  return (
    <BasicLayout>
      <h1>403 - You Shall Not Pass</h1>
      <Link
        to="/sign-out"
        state={{
          callbackUrl: location?.state?.callbackUrl ? location?.state?.callbackUrl : undefined,
        }}
      >
        Back to Login
      </Link>
    </BasicLayout>
  );
};

export default UnauthorizedView;
