import React from 'react';
import './Modal/QuickViewForm.css';
import { Header, Button, Grid, GridColumn, Popup } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    customerGrid: () => ({
      borderRadius: '5px',
    }),
    gridCellValue: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  { name: 'CustomerInfo' },
);

const CustomerInfo = ({ reservation }) => {
  const classes = useStyles();
  const customer = reservation.customer;
  return (
    <div>
      <Button primary floated="right">
        Edit Info +
      </Button>
      <Header as="h2" content="Customer Information" textAlign="left" />
      <Grid celled className={classes.customerGrid} stackable>
        <GridColumn
          color={Colors.gridCell}
          computer={1}
          tablet={2}
          className={classes.customerGrid}
        >
          Name
        </GridColumn>
        <Popup
          content={customer.firstName + ' ' + customer.lastName}
          header="Name"
          trigger={
            <GridColumn className={classes.gridCellValue} computer={2} tablet={2}>
              {customer.firstName + ' ' + customer.lastName}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={1} tablet={2}>
          Mobile
        </GridColumn>
        <Popup
          content={customer.mobile}
          header="Mobile"
          trigger={
            <GridColumn className={classes.gridCellValue} computer={2} tablet={2}>
              {customer.mobile}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={1} tablet={2}>
          Email
        </GridColumn>
        <Popup
          content={customer.email}
          header="Email"
          trigger={
            <GridColumn className={classes.gridCellValue} computer={2} tablet={2}>
              {customer.email}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={1} tablet={2}>
          Address
        </GridColumn>
        <Popup
          content={customer.fullAddress}
          header="Address"
          trigger={
            <GridColumn className={classes.gridCellValue} computer={6} tablet={2}>
              {customer.fullAddress}
            </GridColumn>
          }
        />
      </Grid>
    </div>
  );
};

export default React.memo(CustomerInfo);
