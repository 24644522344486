import React, { useState, useEffect, useRef } from 'react';
import ModalForm from '../ModalForm';
import { Form, Button } from 'semantic-ui-react';
import { listAvailableRooms, updateRatesForRoomAssign, assignRoom } from '@/api/reservationAPI';
import AssignItem from './AssignItem';
import './ReservationRoom.css';

const AssignRoomForm = ({ onClose, reservationRoom, onSave }) => {
  const [roomList, setRoomList] = useState(null);
  const [otherRoomList, setOtherRoomList] = useState(null);
  const [dayRateList, setDayRateList] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(
    reservationRoom != null && reservationRoom.room != null ? reservationRoom.room.id : null,
  );

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  let style = {
    height: windowSize.current[1] - 280 + 'px',
  };

  useEffect(() => {
    let request = {
      reservationRoomId: reservationRoom.id,
    };
    listAvailableRooms(request)
      .then((response) => {
        setRoomList(response.data.roomList);
        setOtherRoomList(response.data.otherTypesRoomList);
      })
      .catch(onError);
  }, []);

  const onRoomSlected = (item) => {
    setSelectedRoom(item.id);
    let request = {
      selectedRoomId: item.id,
      reservationRoomId: reservationRoom.id,
    };
    updateRatesForRoomAssign(request)
      .then((response) => {
        setDayRateList(response.data.dayRateList);
      })
      .catch(onError);
  };

  const onSaveClicked = () => {
    let request = {
      selectedRoomId: selectedRoom,
      reservationRoomId: reservationRoom.id,
      dayRateList: dayRateList,
      updateRates: false,
    };
    assignRoom(request)
      .then((response) => {
        onSave(response);
      })
      .catch(onError);
  };

  const onError = (e) => {
    console.log(e);
  };

  return (
    <Form>
      <div className="rooms-content" style={style}>
        <div>Available West Studio - {reservationRoom != null && reservationRoom.roomType}</div>
        <div className="assign-rooms-content">
          {roomList != null &&
            roomList.map((item, idx) => (
              <AssignItem
                item={item}
                key={idx}
                selectedRoom={selectedRoom}
                onRoomSlected={onRoomSlected}
              ></AssignItem>
            ))}
        </div>
        <div>Other Available Units</div>
        <div className="assign-rooms-content">
          {otherRoomList != null &&
            otherRoomList.map((item, idx) => (
              <AssignItem
                item={item}
                key={idx}
                selectedRoom={selectedRoom}
                onRoomSlected={onRoomSlected}
              ></AssignItem>
            ))}
        </div>
      </div>
      <ModalForm.Actions>
        <Button onClick={onSaveClicked} type="submit" primary>
          Save
        </Button>
        <Button color="red" onClick={onClose}>
          Cancel
        </Button>
      </ModalForm.Actions>
    </Form>
  );
};

export default React.memo(AssignRoomForm);
