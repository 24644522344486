import { useSelector } from 'react-redux';

export const sessionSelector = () => useSelector((state) => state.session);
export const reservationSelector = () => useSelector((state) => state.reservation);

const selectors = {
  sessionSelector,
  reservationSelector,
};

export default selectors;
