import React from 'react';
import './Modal/QuickViewForm.css';
import { Header, Grid, GridColumn, Popup } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

const useStyles = createUseStyles(
  {
    grid: () => ({
      borderRadius: '5px',
    }),
    gridCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxShadow: '-1px -1px 0px 0px #D4D4D5 !important',
    },
    borderTopLeft: {
      borderTopLeftRadius: '5px',
    },
    borderBottomLeft: {
      borderBottomLeftRadius: '5px',
    },
  },
  { name: 'ReservationInfo' },
);

const ReservationInfo = ({ reservation }) => {
  console.log(reservation);
  const classes = useStyles();
  const customer = reservation.customer;
  return (
    <div>
      <Header as="h2" content="Reservation Info" textAlign="left" />
      <Grid celled className={classes.grid} stackable>
        <GridColumn
          color={Colors.gridCell}
          computer={2}
          tablet={4}
          className={classnames(classes.gridCell, classes.borderTopLeft)}
        >
          Reservation
        </GridColumn>
        <Popup
          content={customer.firstName + ' ' + customer.lastName}
          header="Reservation"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {reservation.id}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Status
        </GridColumn>
        <Popup
          content={customer.firstName + ' ' + customer.lastName}
          header="Status"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {reservation.status}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Date Created
        </GridColumn>
        <Popup
          content={customer.mobile}
          header="Gross Total"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.mobile}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Balance Due
        </GridColumn>
        <Popup
          content={customer.email}
          header="Other Charges"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.email}
            </GridColumn>
          }
        />

        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Amount Paid
        </GridColumn>
        <Popup
          content={customer.email}
          header="Room Charges"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.email}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Gross Total
        </GridColumn>
        <Popup
          content={customer.mobile}
          header="Gross Total"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.mobile}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Room Charges
        </GridColumn>
        <Popup
          content={customer.email}
          header="Room Charges"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.email}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Other Charges
        </GridColumn>
        <Popup
          content={customer.email}
          header="Other Charges"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.email}
            </GridColumn>
          }
        />

        <GridColumn
          color={Colors.gridCell}
          computer={2}
          tablet={4}
          className={classnames(classes.borderBottomLeft, classes.gridCell)}
        >
          Mop
        </GridColumn>
        <Popup
          content={customer.firstName + ' ' + customer.lastName}
          header="Status"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.firstName + ' ' + customer.lastName}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Fees
        </GridColumn>
        <Popup
          content={customer.mobile}
          header="Gross Total"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.mobile}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Taxes
        </GridColumn>
        <Popup
          content={customer.email}
          header="Other Charges"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.email}
            </GridColumn>
          }
        />
        <GridColumn color={Colors.gridCell} computer={2} tablet={4} className={classes.gridCell}>
          Deposit Required
        </GridColumn>
        <Popup
          content={customer.email}
          header="Room Charges"
          trigger={
            <GridColumn className={classes.gridCell} computer={2} tablet={4}>
              {customer.email}
            </GridColumn>
          }
        />
      </Grid>
    </div>
  );
};

export default React.memo(ReservationInfo);
