import React from 'react';
import './Modal/QuickViewForm.css';

const ReservationRoomDates = ({reservationRoom}) => {
  return (
    <>
                    <div className="res-table__row room__date-picker">
                        <label className="reservation-room-datepicker"><i className="icon-check-in"></i></label>
                        <p className="room__check-in" id="id7e7"><span>{reservationRoom.checkIn}</span>
                        </p>
                        <label className="reservation-room-datepicker"><i className="icon-check-out"></i>
                        </label>
                        <p className="room__check-out" id="id7e8"><span>{reservationRoom.checkOut}</span></p>
                        <div className="room__nights">
                            <i className="icon-moon-white icon-moon-white-margin"></i>
                            <p><span>{reservationRoom.nights}</span></p>
                        </div>
                        <div className="room__people">
                            <i className="icon-little-man-reservation"></i>
                            <p><span className="room__people-label">{reservationRoom.people}</span></p>
                        </div>
                        <div className="room__addon">
                            
                        </div>

                    </div>
                
    </>
  );
};

export default React.memo(ReservationRoomDates);
