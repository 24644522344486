import React from 'react';
import './Modal/QuickViewForm.css';

const ReservationRoomAmounts = ({reservationRoom}) => {
  return (
    <>
    

                    <div className="res-table__row room__charges">
                        <label>Charges</label>
                        <p>$<span><span>{reservationRoom.charges}</span></span></p>
                    </div>
                    <div className="res-table__row room__fees">
                        <label>Fees &amp; Taxes
                            <i className="grey info circle icon fees-popup room-fees-info-icon"></i>
                            <div className="ui top right flowing wide popup" style={{width: "250px"}}>
                                <div className="header" style={{marginBottom: "10px"}}>Fees &amp; Taxes</div>
                                <div className="ui grid">
                                    <div className="two column row" style={{paddingTop: "5px",paddingBottom: "0"}}>
                                        <div className="left floated left aligned white column">
                                            <p style={{fontWeight: "normal",fontFamily: "robotoregular"}}>Resort Fee</p>
                                        </div>
                                        <div className="right floated right aligned white column">
                                            <p style={{fontWeight: "normal",fontFamily: "robotoregular"}}>$<span>80.00</span></p>
                                        </div>
                                    </div>
                                    <div className="three column row" style={{paddingTop: "5px",paddingBottom: "0"}}>
                                        <div className="left floated left aligned white ten wide column">
                                            <p style={{fontEeight: "normal",fontFamily: "robotoregular"}}>Extra Guest(s) Fee</p>
                                        </div>
                                        <div className="right floated right aligned white column">
                                            <p style={{fontWeight: "normal",fontFamily: "robotoregular"}}>$<span>0.00</span></p>
                                        </div>
                                    </div>
                                    <div className="two column row" style={{paddingTop: "5px",paddingBottom: "10px"}}>
                                        <div className="left floated left aligned white column">
                                            <p style={{fontWeight: "normal",fontFamily: "robotoregular"}}>Taxes</p>
                                        </div>
                                        <div className="right floated right aligned white column">
                                            <p style={{fontWeight: "normal",fontFamily: "robotoregular"}}>$<span>91.65</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <p>$<span><span>{reservationRoom.fees}</span></span></p>
                    </div>
                    <div className="res-table__row room__total">
                        <label>Total</label>
                        <p>$<span><span>{reservationRoom.total}</span></span></p>
                    </div>
                    <div className="res-table__row room__rate">
                        <label>Avg Rate</label>
                        <p>$<span><span>{reservationRoom.averageRate}</span></span></p>
                    </div>
                    
    </>
  );
};

export default React.memo(ReservationRoomAmounts);
